/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPresentation = /* GraphQL */ `
  query GetPresentation($id: ID!) {
    getPresentation(id: $id) {
      id
      name
      s3Object {
        bucket
        key
        region
        __typename
      }
      initialPromptId
      rawTranscription
      speakerLabeledTranscription
      dataSourceId
      userId
      chats {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPresentations = /* GraphQL */ `
  query ListPresentations(
    $filter: ModelPresentationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPresentations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        initialPromptId
        rawTranscription
        speakerLabeledTranscription
        dataSourceId
        userId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChat = /* GraphQL */ `
  query GetChat($id: ID!) {
    getChat(id: $id) {
      id
      name
      presentation {
        id
        name
        initialPromptId
        rawTranscription
        speakerLabeledTranscription
        dataSourceId
        userId
        createdAt
        updatedAt
        __typename
      }
      messages {
        nextToken
        __typename
      }
      userId
      createdAt
      updatedAt
      presentationChatsId
      __typename
    }
  }
`;
export const listChats = /* GraphQL */ `
  query ListChats(
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        userId
        createdAt
        updatedAt
        presentationChatsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      content
      type
      chat {
        id
        name
        userId
        createdAt
        updatedAt
        presentationChatsId
        __typename
      }
      userId
      createdAt
      updatedAt
      chatMessagesId
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        type
        userId
        createdAt
        updatedAt
        chatMessagesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInitialPrompt = /* GraphQL */ `
  query GetInitialPrompt($id: ID!) {
    getInitialPrompt(id: $id) {
      id
      content
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInitialPrompts = /* GraphQL */ `
  query ListInitialPrompts(
    $filter: ModelInitialPromptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInitialPrompts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
