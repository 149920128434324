// PresentationDetail.js

import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {listChats, listMessages, getPresentation} from '..//graphql/queries'
import { generateClient } from 'aws-amplify/api';
import {ScrollPanel} from 'primereact/scrollpanel'

import ChatInput from './ChatInput'
import Header from './Header.js'
import '..//styles/Chatbot.css'
import MessageList from './MessageList';
import PresentationMetadata from './PresentationMetadata.js';
const client = generateClient();
    
    

const PresentationDetail = () => {
  const { id } = useParams();
  const [presentation, setPresentation] = useState(null);
  const [chatID, setChatID] = useState('');
  const [messages, setMessages] = useState([]);
  const scrollPanelRef = useRef(null);


  useEffect(() => {
    if(scrollPanelRef.current){
      scrollPanelRef.current.getContent().scrollTop = scrollPanelRef.current.getContent().scrollHeight;
    }

  }, [messages]);

  useEffect(() => {
    const loadPresentation = async () => {
      try {
        const presentationsResponse = await client.graphql({
          query: getPresentation,
          variables: { id: id },
          authMode: 'userPool'
        }
      );
      setPresentation(presentationsResponse.data.getPresentation); // Update state with data
      } 
      catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    const loadMessages = async () => {
      try {
      //first we get the corresponding chatId for our Presentation
      const chatRespone = await client.graphql({
        query: listChats,
        variables: {
          filter: { presentationChatsId: { eq: id } },
          items: {id},
        },
        authMode: 'userPool'
      });
      if(chatRespone.data.listChats.items.length === 0){
        return;
      }
      const chatId = chatRespone.data.listChats.items[0].id;
      setChatID(chatId);
      const messageReponse = await client.graphql({
        query: listMessages,
        variables: {
          filter: {chatMessagesId : {eq: chatId}},
        },
        authMode: 'userPool'
      });
  
      let messages = messageReponse.data.listMessages.items;
      messages =  messages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      setMessages(messages);
    
  
      } 
      catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    loadPresentation();
    loadMessages();
  }, [id, scrollPanelRef]);


  useLayoutEffect(() => {
    
  }, []);

  //Function to load info from presentation endpoint
 

  if (!presentation || presentation.name === "") {
    return(
    <div>
        <Header />
        <div className="metadata-container">
          <p> No information found</p>
          </div>
          <div className="chat-container">
            <p> No chats/summaries found</p>
          </div>
      </div>
    );
  } else if (messages.length === 0) {
    return (
      <div>
        <Header />
        
        <div className="metadata-container">
            <PresentationMetadata presentation={presentation} />
          </div>
          <div className="chat-container">
            <p> No chats/summaries found</p>
          </div>
      </div>
    );
  } else {

    return (
      <div>
        <Header />
        <div className="detail-container">
          <div className="metadata-container">
            <PresentationMetadata presentation={presentation} />
          </div>
          <div className="chat-container">
          <ScrollPanel ref = {scrollPanelRef}  style={{ width: '100%', height: '800px' }}>
            <MessageList messages={messages} />
            <ChatInput chatID={chatID} />
          </ScrollPanel>
            

          </div>
        </div>
      </div>
    );
  }
};

export default PresentationDetail;