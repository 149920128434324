import '..//styles/App.css';
import Header from '..//componenets/Header'
import InterfaceSelector from '../componenets/InterfaceSelector'
import '@aws-amplify/ui-react/styles.css';


const Interface = () => {

    return (
        <>
        <Header/>
        <InterfaceSelector/>
        </>
        
        
      
    );
  };

export default Interface;