/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPresentation = /* GraphQL */ `
  mutation CreatePresentation(
    $input: CreatePresentationInput!
    $condition: ModelPresentationConditionInput
  ) {
    createPresentation(input: $input, condition: $condition) {
      id
      name
      s3Object {
        bucket
        key
        region
        __typename
      }
      initialPromptId
      rawTranscription
      speakerLabeledTranscription
      dataSourceId
      userId
      chats {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePresentation = /* GraphQL */ `
  mutation UpdatePresentation(
    $input: UpdatePresentationInput!
    $condition: ModelPresentationConditionInput
  ) {
    updatePresentation(input: $input, condition: $condition) {
      id
      name
      s3Object {
        bucket
        key
        region
        __typename
      }
      initialPromptId
      rawTranscription
      speakerLabeledTranscription
      dataSourceId
      userId
      chats {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePresentation = /* GraphQL */ `
  mutation DeletePresentation(
    $input: DeletePresentationInput!
    $condition: ModelPresentationConditionInput
  ) {
    deletePresentation(input: $input, condition: $condition) {
      id
      name
      s3Object {
        bucket
        key
        region
        __typename
      }
      initialPromptId
      rawTranscription
      speakerLabeledTranscription
      dataSourceId
      userId
      chats {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createChat = /* GraphQL */ `
  mutation CreateChat(
    $input: CreateChatInput!
    $condition: ModelChatConditionInput
  ) {
    createChat(input: $input, condition: $condition) {
      id
      name
      presentation {
        id
        name
        initialPromptId
        rawTranscription
        speakerLabeledTranscription
        dataSourceId
        userId
        createdAt
        updatedAt
        __typename
      }
      messages {
        nextToken
        __typename
      }
      userId
      createdAt
      updatedAt
      presentationChatsId
      __typename
    }
  }
`;
export const updateChat = /* GraphQL */ `
  mutation UpdateChat(
    $input: UpdateChatInput!
    $condition: ModelChatConditionInput
  ) {
    updateChat(input: $input, condition: $condition) {
      id
      name
      presentation {
        id
        name
        initialPromptId
        rawTranscription
        speakerLabeledTranscription
        dataSourceId
        userId
        createdAt
        updatedAt
        __typename
      }
      messages {
        nextToken
        __typename
      }
      userId
      createdAt
      updatedAt
      presentationChatsId
      __typename
    }
  }
`;
export const deleteChat = /* GraphQL */ `
  mutation DeleteChat(
    $input: DeleteChatInput!
    $condition: ModelChatConditionInput
  ) {
    deleteChat(input: $input, condition: $condition) {
      id
      name
      presentation {
        id
        name
        initialPromptId
        rawTranscription
        speakerLabeledTranscription
        dataSourceId
        userId
        createdAt
        updatedAt
        __typename
      }
      messages {
        nextToken
        __typename
      }
      userId
      createdAt
      updatedAt
      presentationChatsId
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      content
      type
      chat {
        id
        name
        userId
        createdAt
        updatedAt
        presentationChatsId
        __typename
      }
      userId
      createdAt
      updatedAt
      chatMessagesId
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      content
      type
      chat {
        id
        name
        userId
        createdAt
        updatedAt
        presentationChatsId
        __typename
      }
      userId
      createdAt
      updatedAt
      chatMessagesId
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      content
      type
      chat {
        id
        name
        userId
        createdAt
        updatedAt
        presentationChatsId
        __typename
      }
      userId
      createdAt
      updatedAt
      chatMessagesId
      __typename
    }
  }
`;
export const createInitialPrompt = /* GraphQL */ `
  mutation CreateInitialPrompt(
    $input: CreateInitialPromptInput!
    $condition: ModelInitialPromptConditionInput
  ) {
    createInitialPrompt(input: $input, condition: $condition) {
      id
      content
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateInitialPrompt = /* GraphQL */ `
  mutation UpdateInitialPrompt(
    $input: UpdateInitialPromptInput!
    $condition: ModelInitialPromptConditionInput
  ) {
    updateInitialPrompt(input: $input, condition: $condition) {
      id
      content
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInitialPrompt = /* GraphQL */ `
  mutation DeleteInitialPrompt(
    $input: DeleteInitialPromptInput!
    $condition: ModelInitialPromptConditionInput
  ) {
    deleteInitialPrompt(input: $input, condition: $condition) {
      id
      content
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const uploadPresentation = /* GraphQL */ `
  mutation UploadPresentation($fileName: String!, $initialPromptId: String) {
    uploadPresentation(fileName: $fileName, initialPromptId: $initialPromptId)
  }
`;
export const postMessage = /* GraphQL */ `
  mutation PostMessage(
    $chatId: ID!
    $content: String!
    $type: ParticipantType!
  ) {
    postMessage(chatId: $chatId, content: $content, type: $type) {
      id
      content
      type
      chat {
        id
        name
        userId
        createdAt
        updatedAt
        presentationChatsId
        __typename
      }
      userId
      createdAt
      updatedAt
      chatMessagesId
      __typename
    }
  }
`;
export const secureDeletePresentation = /* GraphQL */ `
  mutation SecureDeletePresentation($presentationId: ID!) {
    secureDeletePresentation(presentationId: $presentationId)
  }
`;
