import '..//styles/Presentations.css';
import Header from '..//componenets/Header'
import DataTable from '..//componenets/Datatable'
import '@aws-amplify/ui-react/styles.css';


const Presentations = () => {

    return (
        <div className="App">
          <Header/>
          <div className = "dataTable-container"> 
            <DataTable/>
          </div>
      </div>
    );
  };

export default Presentations;