import React from 'react';

const Message = ({ text, sender }) => {
    const isUser = (sender === 'USER');
    return (
        <div className={`message ${isUser ? 'user-message' : 'client-message'}`}>
          <p className='break-paragraph'>{text}</p>
        </div>
      );
};

export default Message;