import '..//styles/Interface.css';
import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { useNavigate } from 'react-router-dom';



const Interface = () => {
    const navigate = useNavigate();



    return (
        <div className='about-container'>
                <Card title="More Info"  >
                    <p>
                        This app has been developed to aid in the reaserch process. It is currently in a development status. Please report any bugs to Darwin Mohajeri, at darwin.mohjaeri@austin.utexas.edu 
                    </p>
                </Card>
                
        </div>
        
      
    );
  };

export default Interface;