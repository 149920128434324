import './styles/App.css';
import React from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import FileUploadPage from './Pages/FileUploadPage';
import HomePage from './Pages/HomePage'
import Presentations from './Pages/Presentations';
import Interface from './Pages/Interface'
import PresentationDetail from './componenets/PresentationDetail';
import { withAuthenticator } from '@aws-amplify/ui-react';


function App({ signOut, user }) {
  return (
    <>
    <BrowserRouter>
      <Routes> 
        <Route path = '/' element = {<HomePage/>}/>
        <Route path = '/interface' element = {<Interface/>}/>
        <Route path = '/upload' element = {<FileUploadPage/>}/>
        <Route path = '/presentations' element = {<Presentations/>}/>
        <Route path="/presentations/:id" element={<PresentationDetail/>} />

      </Routes>
    </BrowserRouter>
    </>
    
  );
};

export default withAuthenticator(App);

