import React, { useState, useRef, useEffect  } from 'react';
import { uploadPresentation } from '../graphql/mutations';
import { listInitialPrompts } from '..//graphql/queries';

import { generateClient } from 'aws-amplify/api';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Message } from 'primereact/message';
import { Dropdown } from "primereact/dropdown";

import '../styles/App.css';

const client = generateClient();

const FileUploadComponent = () => {
  const [value, setValue] = useState(0);
  const [message, setMessage] = useState(null); // State for managing messages
  const fileUploadReference = useRef(null);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  // Fetch options from the API
  useEffect(() => {
    const fetchOptions = async () => {
        try {
            const response = await client.graphql({
              query: listInitialPrompts,
              authMode: 'userPool'
            });
            console.log(response);
            const formattedOptions = response.data.listInitialPrompts.items.map(item => ({
                label: item.content, // Display prompt
                value: item.id,   // Corresponding ID
            }));
            setOptions(formattedOptions);
            console.log(formattedOptions);
        } catch (error) {
            console.error("Error fetching options:", error);
        }
    };
    fetchOptions();
  }, []);

  const uploadFile = (file, s3Location) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      Object.keys(s3Location.fields).forEach(key => {
        formData.append(key, s3Location.fields[key]);
      });

      formData.append("file", file);

      const xhr = new XMLHttpRequest();
      xhr.open("POST", s3Location.url, true);

      xhr.upload.onprogress = function (event) {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          setValue(percentComplete.toFixed(2));
        }
      };

      xhr.onerror = function () {
        reject("Some network error occurred: " + xhr.statusText);
      };

      xhr.onload = function () {
        setValue(0);
        resolve();
      };

      xhr.send(formData);
    });
  };

  const uploadFileToS3 = async (event) => {
    if (!event.files) {
      setMessage({ severity: 'error', text: 'Please select a file to upload.' });
      return;
    }
    const numFiles = event.files.length;

    for (const file of event.files) {
      try {
        var uploadResponse;
        if(!selectedOption){
           uploadResponse = await client.graphql({
            query: uploadPresentation,
            variables: { fileName: file.name, },
            authMode: 'userPool'
          });
        }
        else{
          uploadResponse = await client.graphql({
            query: uploadPresentation,
            variables: { fileName: file.name, initialPromptId: selectedOption},
            authMode: 'userPool'
          });
        }
        

        const s3Location = JSON.parse(uploadResponse.data.uploadPresentation.replace(/\\/g, '')).body;
        setMessage({ severity: 'info', text: 'Uploading new file.' });

        await uploadFile(file, s3Location);
      } catch (error) {
        console.error('Error fetching data:', error);
        setMessage({ severity: 'error', text: `Error uploading file: ${file.name}` });
        return;
      }
    }

    setMessage({
      severity: 'success',
      text: numFiles === 1 ? "File uploaded successfully!" : `${numFiles} files were uploaded!`
    });

    fileUploadReference.current.clear();
    setValue(0);
  };

  return (
    <div>
      {message && <Message severity={message.severity} text={message.text} />}
      <Dropdown
                value={selectedOption}
                options={options}
                onChange={(e) => setSelectedOption(e.value)}
                placeholder="Select a Prompt"
                autoWidth={false}
      />
      <FileUpload 
        ref={fileUploadReference} 
        className='file-comp'
        multiple={true} 
        name="demo[]" 
        uploadLabel='Upload & Transcribe'
        customUpload 
        uploadHandler={uploadFileToS3}  
        accept="audio/*, video/*"  
        maxFileSize={100000000000} 
        emptyTemplate={<p className="file-upload-content">Drag and drop files here to upload.</p>} 
      />
      <ProgressBar value={value}></ProgressBar>

      {/* Display Message component if there's a message */}
      
    </div>
  );
};

export default FileUploadComponent;