import '..//styles/Header.css';
import React from 'react';
import '@aws-amplify/ui-react/styles.css';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';




function Header() {
    const { user, signOut } = useAuthenticator((context) => [context.user]);
    const navigate = useNavigate();

    return (
        <div className="nav-bar-header">
            
            <div className="nav-bar-options">
                <img className='logo' src='/logo.png' alt = 'Logo' height='50px' align = "left" padding = "0px"/>
                <button className='clear-button' onClick={() => navigate('/', { replace: true })}> Home</button>
                <button className='clear-button' onClick={() =>navigate('/upload/', { replace: true })}> Upload File</button>
                <button className='clear-button' onClick={() => navigate('/presentations/', { replace: true })}> Previous Uploads</button>
            </div> 

            <div className = 'right-side-nav-bar-options'>
                <h1 className='clear-text'>Welcome, {user.username}</h1>
                <button className='clear-button' onClick={signOut}> Sign Out</button>
            </div>

        </div>
    );
}
export default Header;