import '..//styles/App.css';
import React, { useState } from 'react';

import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';

import { postMessage } from '../graphql/mutations';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();


const ChatInput = ({chatID}) => {
    const [inputText, setInputText] = useState('');
    const [loading, setLoading] = useState(false);
    const createNewChat = async () => {
      if(!inputText){
        return;
      }

      setLoading(true);
      const id = chatID; 
      try {
        // POST REQUEST TO DB to get S3 bucket upload link
        const postResponse = await client.graphql({
            query: postMessage,
            variables: {chatId: id, content: inputText, type: "USER"},
            authMode: 'userPool'
        });

        console.log(postResponse);
        setInputText(''); // Clear the input field after sending
        setLoading(false);
        window.location.reload(); // This will refresh the entire page

    } catch (error) {
        console.error('Error posting chat:', error);
        alert('Error posting chat');
    }

    }
    return(
        <div className="input-field">
              <InputTextarea
                disabled = {loading}
                value={inputText}
                onChange={(e) => setInputText(e.target.value)} // Correct onChange handler
                rows={5}
                cols={30}
              />
              <Button label={loading ? 'Sending...' : 'Send Message'} 
              onClick={createNewChat}
              rounded 
              disabled={loading}/>
            </div>
    )
}

export default ChatInput;