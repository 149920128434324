import React from 'react';

const Transcript = ({ transcript }) => {
  // Regular expression to match speaker labels like spk_1, spk_2, etc.
  const speakerPattern = /(spk_\d+:)/;
  var lines;
  // Split the transcript by the speaker labels, retaining the labels
  if(transcript){
    lines = transcript.split(speakerPattern).filter(Boolean);
  }
  else{
    lines = "";
  }

  return (
    <div>
      {lines.map((line, index) => (
        <p key={index} style={{ margin: '10px 0' }}>
          {line.trim()}
        </p>
      ))}
    </div>
  );
};

export default Transcript;