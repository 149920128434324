import '..//styles/Interface.css';
import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';



const Interface = () => {
    const navigate = useNavigate();

    const header = (
        <img alt="Card" src="https://primefaces.org/cdn/primereact/images/usercard.png" />
    );


    const uploadFooter = (
        <>
        <Button label="Upload Files" icon="pi pi-check" onClick={() => navigate('/upload/', { replace: true })}/>
        </>
    );
    const presFooter = (
        <>
        <Button label="Archive" icon="pi pi-check" onClick={() => navigate('/presentations/', { replace: true })}/>
        </>
    );

    return (
        <div className='interface-container'>
            <div class="responsive-two-column-grid">
                <Card title="See Previously Uploaded Files!" subTitle="File Archive" header ={header} footer={presFooter} >
                    <p >
                        See previously uploaded files, and continue the conversation! Sort by date or search for the file name, and choose.
                    </p>
                </Card>
                <Card title="Upload File!" subTitle="Generate a new summary" header ={header} footer={uploadFooter} >
                    <p>
                    Or upload a new file entirely! Start the conversation anew with a new file.
                    </p>
                </Card>
            </div>
        </div>
        
      
    );
  };

export default Interface;