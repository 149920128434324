import '..//styles/App.css';
import React from 'react';
import Header from '..//componenets/Header'
import InterfaceSelector from '../componenets/InterfaceSelector'
import Info from '../componenets/Info'
import '@aws-amplify/ui-react/styles.css';



function HomePage() {
  return (
    <div className = "App">
        <Header/>
        <div className = "home-body">
          <InterfaceSelector/>
          <Info/>
        </div>
    </div>
        
      
    
  );
}

export default (HomePage);