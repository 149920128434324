import React, { useState } from "react";
import '..//styles/Presentations.css'
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dialog } from 'primereact/dialog';
import TranscriptTrimmed from './TranscriptTrimmed'; // Adjust the path as needed

const PresentationMetadata = ({ presentation }) => {
    const [visible, setVisible] = useState(false);
    
    const downloadTranscript = () => {
        if(!presentation.speakerLabeledTranscription){
            return;
        }
        const element = document.createElement('a');
        const file = new Blob([presentation.speakerLabeledTranscription], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = presentation.name + '-transcription.txt'
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        document.body.removeChild(element); // Clean up
    }
  return (
    <div className="presentation-metadata">
        <Accordion multiple activeIndex={[0,1,2]}>
            <AccordionTab header="Transcription">
                    <button onClick = {downloadTranscript}> Download as .txt</button>
                    <button onClick={() => setVisible(true)}> Show</button>
            </AccordionTab>
            <AccordionTab header="File Name">
                <p>{presentation.name}</p>
            </AccordionTab>
            <AccordionTab header="Date Uploaded">
                <p>{presentation.createdAt}</p>
            </AccordionTab>          
        </Accordion>
        <Dialog header="Transcription" visible={visible} style={{ width: '50vw' }} onHide={() => {if (!visible || !presentation.speakerLabeledTranscription) return; setVisible(false); }}>
            <TranscriptTrimmed transcript={presentation.speakerLabeledTranscription} />
        </Dialog>
    </div>
  );
};

export default PresentationMetadata;