import '..//styles/App.css';
import React from 'react';
import Header from '../componenets/Header';
import FileUploadComponent from '../componenets/FileUploadComponent';
import { Card } from 'primereact/card';


const FileUploadPage = () => {

    return (
      <div className="App">
        <Header/>

        <div className='file-upload-container'>
        <Card title="Upload File" className='file-upload-card'>
                <p className="m-0">
                    This is the interface to upload files. Simply select upload and choose a video file to be processed. Please note that we have only tested with video
                    files at the moment. Once you have uploaded the appropriate files, select the initial prompt from the dropdown to change the initial summary provided.
                    After the file has succesfully uploaded, navigate to the previous uploads page and select the file from the table. Also note that
                    the transcription process may take some time, so refresh the page if the transcription says "Loading...". 
                </p>

                <p>
                  The list of supported video file types are: .ogm, .wmb., mpg, .webm, .ogv, .mov, .asx, .mpeg, .mp4, .m4v, .avi
                </p>

                <p>
                  The list of supported aduio file types are: .opus, .flac, .webm, .weba, .wav, .ogg, .mid, .mp3, .aiff, .wma, .au
                </p>
                <FileUploadComponent/>
          </Card>
          
        </div>

      </div>
      
    );
  };

export default FileUploadPage;